import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-spin-loader',
  templateUrl: './spin-loader.component.html',
  styleUrls: ['./spin-loader.component.scss']
})
export class SpinLoaderComponent implements OnInit {

    @Input()
    public loadingText = '';
    @Input()
    public marginTop = '3px';

  constructor() { }

  ngOnInit(): void {
  }

}
