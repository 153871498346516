import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ConstantsProvider} from './constants-provider.service';
import {map} from 'rxjs/operators';
import {Lecturer} from '../models/lecturer.model';
import {GenericDataProviderInterface} from "../gem-util/GenericDataProviderInterface";

@Injectable()
export class LecturersProvider implements GenericDataProviderInterface {
    constructor(
        private _httpClient: HttpClient,
        private constantsService: ConstantsProvider
    ) {
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */

    /**
     * Get single-state
     *
     * @returns {Promise<any>}
     */
    fetchDataList(schoolId?): Observable<any> {
        const bgColors = ['web', 'android', 'cloud', 'firebase', 'cloud', 'android', 'web', 'firebase'];
        return this._httpClient.get(this.constantsService.url + 'api/Lecturers', this.constantsService.getAuthHeaderOption())
            .pipe(map((result: any) => {
                    result.forEach(res => {
                        const index = result.indexOf(res);
                        res.bgColor = bgColors[(index % bgColors.length)];
                        res.user = (res.user.length && res.user[0]) ? res.user[0] : res.user;
                    });
                    return result;
                })
            );
    }

    fetchSingle(lecturerId): Observable<any> {
        return this._httpClient.get(this.constantsService.url + 'api/Lecturers/' + lecturerId,
            this.constantsService.getAuthHeaderOption());
    }

    /**
     * Save state
     *
     * @param lecturer
     * @returns {Promise<any>}
     */
    update(lecturer): Observable<any> {
        return this._httpClient.put(this.constantsService.url + 'api/Lecturers/' + lecturer.id, lecturer,
                this.constantsService.getAuthHeaderOption());
    }

    /**
     * Add state
     *
     * @returns {Promise<any>}
     * @param lecturer
     */
    create(lecturer: Lecturer): Observable<any> {
        delete lecturer.id;
        return this._httpClient.post(this.constantsService.url + 'api/Lecturers', lecturer,
                this.constantsService.getAuthHeaderOption());
    }


    fetchUserLecturerData(userId): Observable<any> {
        return this._httpClient.get(this.constantsService.url + 'api/Lecturers/user/' + userId,
            this.constantsService.getAuthHeaderOption());
    }
}
