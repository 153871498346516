export const locale = {
    lang: 'en',
    data: {
        nav: {
            setup: {
                title: 'Basic Setup',
                states: 'States',
                cities: 'Cities',
                school_types: 'School Types',
                roles_and_permissions: 'Roles and Permissions',
                users: 'System Users'
            },
            menu: {
                title: 'Application Menu',
                dashboard: 'Dashboard',
            },
            academics: {
                title: 'Academics Setup',
                departments: 'Departments',
                schools: 'Schools',
                courses: 'Courses',
                lecturers: 'Lecturers',
                lectures: 'Lectures',
                sessions: 'Sessions',
                course_categories: 'Course Categories',
                classes: 'Classes',
            }
        }
    }
};
