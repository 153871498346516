import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuardProvider} from './services/auth-guard-provider.service';
import {ReverseAuthGuardProvider} from './services/reverse-auth-guard-provider.service';

const appRoutes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'app'
    },
    {
        path: 'app',
        canActivate: [AuthGuardProvider],
        loadChildren: () => import('./main/main.module').then(m => m.MainModule)
    },
    {
        path: 'account',
        canActivate: [ReverseAuthGuardProvider],
        loadChildren: () => import('./main/authentication/authentication.module').then(m => m.AuthenticationModule)
    },
    {
        path: '**',
        loadChildren: () => import('./main/404/error-404.module').then(m => m.Error404Module)
    }
];

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        RouterModule.forRoot(appRoutes)
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
