import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {School} from '../models/school.model';
import {ConstantsProvider} from './constants-provider.service';
import {map} from 'rxjs/operators';
import {Classes} from '../models/classes.model';
import {isArray} from 'util';
import {GenericDataProviderInterface} from '../gem-util/GenericDataProviderInterface';

@Injectable()
export class AdmissionApplicationProvider implements GenericDataProviderInterface {
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     * @param {Router} router
     * @param {ConstantsProvider} constantsService
     */
    constructor(
        private _httpClient: HttpClient,
        private router: Router,
        private constantsService: ConstantsProvider
    ) {
    }

    fetchDataList(schoolId): Observable<any> {
        const bgColors = ['web', 'android', 'cloud', 'firebase', 'cloud', 'android', 'web', 'firebase'];
        return this._httpClient.get(this.constantsService.url + 'api/AdmissionApplications', this.constantsService.getAuthHeaderOption())
            .pipe(map((result: any) => {
                    result.forEach(res => {
                        const index = result.indexOf(res);
                        res.bgColor = bgColors[(index % bgColors.length)];

                        if (!res.parentId) {
                            res.parentId = '';
                        }

                        if (res.parentClass) {
                            if (isArray(res.parentClass) && res.parentClass.length) {
                                res.parentClass = res.parentClass[0];
                                res.parentId = res.parentClass.id;
                            }
                        }
                    });
                    console.log('Result', result);
                    return result;
                })
            );
    }

    fetchSingle(classesId): Observable<any> {
        return this._httpClient.get(this.constantsService.url + 'api/AdmissionApplications/' + classesId,
            this.constantsService.getAuthHeaderOption()).pipe(map((res: any) => {
                if (isArray(res)) {
                    res = res[0];
                }
                if (!res.parentId) {
                    res.parentId = '';
                }

                if (res.parentClass) {
                    if (isArray(res.parentClass) && res.parentClass.length) {
                        res.parentClass = res.parentClass[0];
                        res.parentId = res.parentClass.id;
                    }
                }
                return res;
            })
        );
    }

    /**
     * Save state
     *
     * @param classes
     * @returns {Promise<any>}
     */
    update(classes): Observable<any> {
        return this._httpClient.put(this.constantsService.url + 'api/AdmissionApplications/' + classes.id, classes,
            this.constantsService.getAuthHeaderOption());
    }

    /**
     * Add state
     *
     * @returns {Promise<any>}
     * @param classes
     */
    create(classes: Classes): Observable<any> {
        return this._httpClient.post(this.constantsService.url + 'api/AdmissionApplications', classes,
            this.constantsService.getAuthHeaderOption());
    }

}
