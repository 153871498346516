import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {isArray} from 'util';
import {ConstantsProvider} from './constants-provider.service';
import {GenericDataProviderInterface} from '../gem-util/GenericDataProviderInterface';

@Injectable()
export class SchoolsProvider implements GenericDataProviderInterface{

    constructor(private _httpClient: HttpClient,
                private constantsService: ConstantsProvider) {
    }

    /**
     * Get boards
     *
     * @returns {Promise<any>}
     */
    fetchDataList(): Observable<any> {
        const user = this.constantsService.getAuthenticatedUser() || {};
        // 'api/Schools/owner/' + user.sub
        return this._httpClient.get(this.constantsService.url + 'api/Schools/owner/' + user.sub, this.constantsService.getAuthHeaderOption());
    }

    /**
     * Get board
     *
     * @param schoolId
     * @returns {Promise<any>}
     */
    fetchSingle(schoolId): Observable<any> {
        return this._httpClient.get(this.constantsService.url + 'api/Schools/' + schoolId, this.constantsService.getAuthHeaderOption()).pipe(
            map(result => {
                if (isArray(result)) {
                    result = result[0];
                }

                return result;
            })
        );
    }

    create(data: any): Observable<any> {
        return undefined;
    }

    update(data): Observable<any> {
        return undefined;
    }

}
