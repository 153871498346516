import {FuseNavigation} from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id: 'menu',
        title: 'Application Menu',
        translate: 'nav.menu.title',
        type: 'group',
        icon: 'apps',
        children: [
            {
                id: 'dashboard',
                title: 'Dashboard',
                translate: 'nav.menu.dashboard',
                type: 'item',
                icon: 'dashboard',
                url: '/app/dashboard'
            },
            {
                id: 'nav.setup.schools',
                title: 'Schools',
                translate: 'nav.academics.schools',
                type: 'item',
                icon: 'school',
                url: '/app/setup/schools'
            },
            {
                id: 'app-setup',
                title: 'Basic Setup',
                type: 'collapsable',
                translate: 'nav.setup.title',
                icon: 'pages',
                children: [
                    {
                        id: 'nav.setup.states',
                        title: 'States',
                        translate: 'nav.setup.states',
                        type: 'item',
                        icon: 'my_location',
                        url: '/app/setup/states'
                    },
                    {
                        id: 'nav.setup.cities',
                        title: 'Cities',
                        translate: 'nav.setup.cities',
                        type: 'item',
                        icon: 'location_city',
                        url: '/app/setup/cities'
                    },
                    {
                        id: 'nav.setup.school-types',
                        title: 'School Types',
                        translate: 'nav.setup.school_types',
                        type: 'item',
                        url: '/app/setup/school-types',
                        icon: 'school',
                    },

                    {
                        id: 'nav.setup.roles',
                        title: 'Roles & Permissions',
                        translate: 'nav.setup.roles_and_permissions',
                        type: 'item',
                        url: '/app/setup/roles',
                        icon: 'security',
                    },
                    {
                        id: 'nav.setup.users',
                        title: 'System Users',
                        translate: 'nav.setup.users',
                        type: 'item',
                        url: '/app/setup/users',
                        icon: 'supervised_user_circle',
                    },
                ]
            },
        ]
    },
];
