import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Department} from '../models/department.model';
import {ConstantsProvider} from './constants-provider.service';
import {map} from 'rxjs/operators';
import {GenericDataProviderInterface} from "../gem-util/GenericDataProviderInterface";

@Injectable()
export class DepartmentsProvider implements GenericDataProviderInterface {
    constructor(
        private _httpClient: HttpClient,
        private constantsService: ConstantsProvider
    ) {
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */

    /**
     * Get single-state
     *
     * @returns {Promise<any>}
     */
    fetchDataList(schoolId): Observable<any> {
        const bgColors = ['web', 'android', 'cloud', 'firebase', 'cloud', 'android', 'web', 'firebase'];
        return this._httpClient.get(this.constantsService.url + 'api/Departments/school/' + schoolId, this.constantsService.getAuthHeaderOption())
            .pipe(map((result: any) => {
                    result.forEach(res => {
                        const index = result.indexOf(res);
                        res.bgColor = bgColors[(index % bgColors.length)];
                    });
                    return result;
                })
            );
    }

    fetchSingle(departmentId): Observable<any> {
        return this._httpClient.get(this.constantsService.url + 'api/Departments/' + departmentId,
            this.constantsService.getAuthHeaderOption());
    }

    /**
     * Save state
     *
     * @param department
     * @returns {Promise<any>}
     */
    update(department): Observable<any> {
        return this._httpClient.put(this.constantsService.url + 'api/Departments/' + department.id, department,
                this.constantsService.getAuthHeaderOption());
    }

    /**
     * Add state
     *
     * @returns {Promise<any>}
     * @param department
     */
    create(department: Department): Observable<any> {
        department = {
            schoolId: department.schoolId,
            parentId: department.parentId,
            departmentName: department.departmentName,
        };
        delete department.id;
        return this._httpClient.post(this.constantsService.url + 'api/Departments', department,
                this.constantsService.getAuthHeaderOption());
    }

}
