import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';
import 'hammerjs';

import {FuseModule} from '@fuse/fuse.module';
import {FuseSharedModule} from '@fuse/shared.module';
import {FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule} from '@fuse/components';

import {fuseConfig} from 'app/fuse-config';

import {AppComponent} from 'app/app.component';
import {AppStoreModule} from 'app/store/store.module';
import {LayoutModule} from 'app/layout/layout.module';
import {AuthGuardProvider} from './services/auth-guard-provider.service';
import {AuthProvider} from './services/auth-provider.service';
import {CurrentUserProvider} from './services/current-user-provider.service';
import {AppRoutingModule} from './app-routing.module';
import {ReverseAuthGuardProvider} from './services/reverse-auth-guard-provider.service';
import {NotifierModule} from 'angular-notifier';
import {ConstantsProvider} from './services/constants-provider.service';
import {DepartmentsProvider} from './services/departments-provider.service';
import {SchoolsProvider} from './services/schools-provider.service';
import {SessionsProvider} from './services/sessions-provider.service';
import {ClassesProvider} from './services/classes-provider.service';
import {CourseCategoriesProvider} from './services/course-categories-provider.service';
import {UsersProvider} from './services/users-provider.service';
import {RolesProvider} from './services/roles-provider.service';
import {SlugifyPipe} from './pipes/slugify.pipe';
import {CoursesProvider} from './services/courses-provider.service';
import {SchoolTypesProvider} from './services/schools-types-provider.service';
import {LecturersProvider} from './services/lecturers-provider.service';
import {LecturePlansProvider} from './services/lecture-plans-provider.service';
import {LecturesProvider} from './services/lectures-provider.service';
import {AdhocCoursesProvider} from './services/adhoc-courses-provider.service';
import {AdmissionApplicationProvider} from './services/admission-application-provider.service';
import {StudentsProvider} from './services/students-provider.service';

@NgModule({
    declarations: [
        AppComponent,
        SlugifyPipe
    ],
    imports: [
        BrowserModule, BrowserAnimationsModule, HttpClientModule, AppRoutingModule,

        TranslateModule.forRoot(),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule, MatIconModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig), FuseProgressBarModule, FuseSharedModule, FuseSidebarModule, FuseThemeOptionsModule,

        // App modules
        LayoutModule, AppStoreModule, NotifierModule
    ],
    bootstrap: [
        AppComponent
    ],
    providers: [
        ConstantsProvider,
        AuthGuardProvider, AuthProvider, CurrentUserProvider, ReverseAuthGuardProvider,
        DepartmentsProvider,
        SchoolsProvider,
        SessionsProvider,
        ClassesProvider,
        CourseCategoriesProvider,
        UsersProvider,
        RolesProvider,
        LecturersProvider,
        LecturesProvider,
        CoursesProvider,
        SchoolTypesProvider,
        LecturePlansProvider,
        AdhocCoursesProvider,
        AdmissionApplicationProvider,
        StudentsProvider,
        SlugifyPipe,
        
    ]
    
})
export class AppModule {
}
