import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CurrentUserProvider {

  constructor() { }

  getLoggedInUser(): any {
      return {};
  }

  setLoggedInUser(): void {

  }
}
