import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ConstantsProvider} from './constants-provider.service';
import {map} from 'rxjs/operators';
import {GenericDataProviderInterface} from '../gem-util/GenericDataProviderInterface';

@Injectable()
export class CourseCategoriesProvider implements GenericDataProviderInterface {

    constructor(
        private _httpClient: HttpClient,
        private constantsService: ConstantsProvider
    ) {
    }

    /**
     * Get categories
     *
     * @returns {Promise<any>}
     */
    fetchDataList(schoolId): Observable<any> {
        const bgColors = ['web', 'android', 'cloud', 'firebase', 'cloud', 'android', 'web', 'firebase'];
        return this._httpClient.get(
            this.constantsService.url + 'api/CourseCategories/school/'+schoolId,
            this.constantsService.getAuthHeaderOption()
        ).pipe(map((response: any) => {
            response.forEach(res => {
                const index = response.indexOf(res);
                res.bgColor = bgColors[(index % bgColors.length)];
            });
            return response;
        }));
    }

    fetchSingle(courseCategoryId): Observable<any> {
        return this._httpClient.get(
            this.constantsService.url + 'api/CourseCategories/' + courseCategoryId,
            this.constantsService.getAuthHeaderOption()
        );
    }

    create(data): Observable<any> {
        delete data.id;
        return this._httpClient.post(
            this.constantsService.url + 'api/CourseCategories', data,
            this.constantsService.getAuthHeaderOption()
        );
    }

    update(data): Observable<any> {
        return this._httpClient.put(this.constantsService.url + 'api/CourseCategories/' + data.id,
            data, this.constantsService.getAuthHeaderOption()
        );
    }

}
