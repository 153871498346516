import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {ConstantsProvider} from './constants-provider.service';

@Injectable()
export class AuthGuardProvider implements CanActivate {
  constructor(private router: Router, private constantsService: ConstantsProvider) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<boolean> | boolean {
    if (!this.constantsService.isUserAuthenticated()) {
        this.constantsService.setReturnUrl(location.href);
        // navigate to login page
        this.constantsService.error('Your session expired, please login first');
        this.router.navigate(['/account/login']);
        return false;
    }


    return true;


  }
}
