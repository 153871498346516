import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Session} from '../models/session.model';
import {ConstantsProvider} from './constants-provider.service';
import {map} from 'rxjs/operators';
import {GenericDataProviderInterface} from '../gem-util/GenericDataProviderInterface';

@Injectable()
export class SessionsProvider implements GenericDataProviderInterface{
    constructor(
        private _httpClient: HttpClient,
        private constantsService: ConstantsProvider
    ) {
    }

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */

    /**
     * Get single-state
     *
     * @returns {Promise<any>}
     */
    fetchDataList(schoolId): Observable<any> {
        const bgColors = ['web', 'android', 'cloud', 'firebase', 'cloud', 'android', 'web', 'firebase'];
        return this._httpClient.get(this.constantsService.url + 'api/Sessions/school/'+schoolId, this.constantsService.getAuthHeaderOption())
            .pipe(map((result: any) => {
                    result.forEach(res => {
                        const index = result.indexOf(res);
                        res.bgColor = bgColors[(index % bgColors.length)];
                    });
                    return result;
                })
            );
    }

    fetchSingle(sessionId): Observable<any> {
        return this._httpClient.get(this.constantsService.url + 'api/Sessions/' + sessionId,
            this.constantsService.getAuthHeaderOption());
    }

    /**
     * Save state
     *
     * @param session
     * @returns {Promise<any>}
     */
    update(session): Observable<any> {
        return this._httpClient.put(this.constantsService.url + 'api/Sessions/' + session.id, session,
                this.constantsService.getAuthHeaderOption());
    }

    /**
     * Add state
     *
     * @returns {Promise<any>}
     * @param session
     */
    create(session: Session): Observable<any> {
        session = {
            schoolId: session.schoolId,
            parentId: session.parentId,
            sessionName: session.sessionName,
        };
        return this._httpClient.post(this.constantsService.url + 'api/Sessions', session,
                this.constantsService.getAuthHeaderOption());
    }

}
