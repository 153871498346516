import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ConstantsProvider} from './constants-provider.service';

@Injectable()
export class AuthProvider {

    public setToken(token): void {
        this.constantsService.setToken(token);
    }

    public setClaim(claim): any {
        this.constantsService.setClaim(claim);
    }

    public getAuthenticatedUser(): any {
        return this.constantsService.getAuthenticatedUser();
    }

    public getAuthenticatedUserPermissions(): Array<any>  {
        const user = this.getAuthenticatedUser();
        if (user && user.hasOwnProperty('permission')) {
            return user.permission as Array<string>;
        }
        return [];
    }

    public isUserAuthenticated(): boolean {
        return this.constantsService.isUserAuthenticated();
    }

    public setReturnUrl(url: string): void {
        this.constantsService.setReturnUrl(url);
    }

    can(permission: string): boolean {
        const allPermissions = this.getAuthenticatedUserPermissions();
        const cPerm = permission.split('|');
        // console.log(permission, index);
        // console.log(allPermissions);
        // console.log(cPerm);
        return allPermissions.filter(p => cPerm.indexOf(p) > -1).length > 0;
    }

    getReturnUrl(clearOld): string {
        return this.constantsService.getReturnUrl(clearOld);
    }

    constructor(private http: HttpClient, private router: Router,
                private constantsService: ConstantsProvider) {
    }

    resetPassword(data): Observable<any> {
        return this.http.post<any>(this.constantsService.url + 'account/password/recovery', data, this.constantsService.getOrdinaryHeaderOption());
    }

    checkResetToken(resetToken): Observable<any> {
        return this.http.get(this.constantsService.url + 'account/password/reset/' + resetToken, this.constantsService.getOrdinaryHeaderOption());
    }

    changePassword(data): Observable<any> {
        return this.http.post<any>(this.constantsService.url + 'account/password/new', data, this.constantsService.getOrdinaryHeaderOption());
    }

    login(data: any): Observable<any> {
        return this.http.post<any>(this.constantsService.url + 'connect/token', data, this.constantsService.getOrdinaryHeaderUrlEncodedOption());
    }

    logout(): void {
        // clear token remove user from local storage to log user out
        // this.token = null;
        let nextUrl = null;
        if (localStorage.getItem('nextReturnUrl')) {
            nextUrl = localStorage.getItem('nextReturnUrl');
        }

        localStorage.clear();

        if (nextUrl) {
            this.setReturnUrl(nextUrl);
        }
        this.router.navigate(['/account/login']);
    }

    activateUserAccount(token: string): Observable<any> {
        return this.http.get<any>(this.constantsService.url + 'account/activate/' + token, this.constantsService.getOrdinaryHeaderOption());
    }

    getUserProfile(username: string = 'me'): Observable<any> {
        return this.http.get<any>(this.constantsService.url + 'users/' + username, this.constantsService.getOrdinaryHeaderOption());
    }
}
