import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {ConstantsProvider} from './constants-provider.service';

@Injectable()
export class ReverseAuthGuardProvider implements CanActivate {
    constructor(private router: Router, private constantsService: ConstantsProvider) {
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<boolean> | boolean {
        if (!this.constantsService.isUserAuthenticated()) {
            return true;
        }

        this.constantsService.succeeded('You authenticated already.');
        this.router.navigate(['/app/dashboard']);
        return false;
    }
}
