import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ConstantsProvider} from './constants-provider.service';
import {map} from 'rxjs/operators';

@Injectable()
export class UsersProvider {
    constructor(
        private _httpClient: HttpClient,
        private router: Router,
        private constantsService: ConstantsProvider
    ) {
    }

    /**
     * Get single-user
     *
     * @returns {Promise<any>}
     */
    getUsers(schoolId?): Observable<any> {
        return this._httpClient.get(this.constantsService.url + 'api/Account/users', this.constantsService.getAuthHeaderOption())
            .pipe(
                map((result: any) => {
                    result.map(user => {
                        if (user.profilePics) {
                            user.profilePics = this.constantsService.url + user.profilePics;
                        }
                    });

                    return result;
                })
            );
    }

    getUser(userId): Observable<any> {
        return this._httpClient.get(this.constantsService.url + 'api/Account/users/' + userId, this.constantsService.getAuthHeaderOption());
    }

    create(data): Observable<any> {
        delete data.id;
        return this._httpClient.post(this.constantsService.url + 'api/Account/users', data, this.constantsService.getAuthHeaderOption());
    }

    update(data): Observable<any> {
        return this._httpClient.put(this.constantsService.url + 'api/Account/users/' + data.id, data, this.constantsService.getAuthHeaderOption());
    }
}
