import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ConstantsProvider} from './constants-provider.service';
import {map} from 'rxjs/operators';
import {GenericDataProviderInterface} from '../gem-util/GenericDataProviderInterface';

@Injectable()
export class AdhocCoursesProvider implements GenericDataProviderInterface {

    constructor(
        private _httpClient: HttpClient,
        private constantsService: ConstantsProvider
    ) {
    }

    /**
     * Get courses
     *
     * @returns {Promise<any>}
     */
    fetchDataList(schoolId): Observable<any> {
        const bgColors = ['web', 'android', 'cloud', 'firebase', 'cloud', 'android', 'web', 'firebase'];
        return this._httpClient.get(this.constantsService.url + 'api/AdhocCourses', this.constantsService.getAuthHeaderOption())
            .pipe(map((result: any) => {
                    result.forEach(res => {
                        const index = result.indexOf(res);
                        res.bgColor = bgColors[(index % bgColors.length)];
                    });
                    return result;
                })
            );
    }

    fetchSingle(courseId): Observable<any> {
        return this._httpClient.get(this.constantsService.url + 'api/AdhocCourses/' + courseId, this.constantsService.getAuthHeaderOption());
    }

    create(data): Observable<any> {
        delete data.id;
        return this._httpClient.post(this.constantsService.url + 'api/AdhocCourses', data, this.constantsService.getAuthHeaderOption());
    }

    update(data): Observable<any> {
        return this._httpClient.put(this.constantsService.url + 'api/AdhocCourses/' + data.id, data, this.constantsService.getAuthHeaderOption());
    }

}
